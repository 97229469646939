.body{
  
}
.container {
  max-width: 500px;
  margin:auto;

  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

ul {
  list-style: none;
}

.link {
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

body {
  color: #333;
  background-color: #fff;
}

a {
  color: #9370DB;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
